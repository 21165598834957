
.backIcon i{
  font-size: 30px;
  color: white;
  cursor: pointer;
}
.homeContainer{
    height: 980px;
}
video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
   }
   .content {
    position: fixed;
    left: 700px;
    top: 400px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #f1f1f1;
    
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap:0;
    justify-content: center;
    align-items: center;
  }
  .details{
    font-size: xx-large;
    text-align: center;
  }
  .name{
      color: rgb(100, 7, 72);
      font-weight: 700;
  }
  .buttonAbout{
      background-color: rgb(35, 194, 186);
      padding: 20px;
      color: rgb(255, 255, 255);
      font-size: medium;
      cursor: pointer;
      outline: none;
      border: none;
      border-radius: 20px;
  }
  .mainDiv{
      height: 980px;
      overflow-y: hidden;
    
  }
  .navbar{
      padding-left: 10%;
      height: 60px;
      background: rgba(0, 0, 0, 0.2);
  }
  .navItem{
    display: flex;
    flex-direction: row;
    margin-left: 100px;
    gap:20px
}
.navItem div{
  color: white;
  font-size: large;
  font-weight: 600;

}
.skill{
   
   display: flex;
   flex-direction: column;
   gap:40px
}

  .skillset{
      color:white;
      width: 200px;
      font-size: large;
      font-weight: 500;
      height: 10px;
  }

  
  .bg-SOME_NAME{
    background: linear-gradient(-180deg, white, #fda3b2) !important;
  }  
  .progress{
      width: 300px;
      border-radius: 4px !important;
      height: 20px !important;
  }
  body {
  
    background: linear-gradient(18deg, #e37682, #a58fe9);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .hexagon-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: initial;
    width: 200px;
    height: 200px;
    cursor: pointer;
  }
  
  .hexagon {
    position: relative;
    width: 46%;
    height: 80%;
    margin: auto;
    color: white;
    background: linear-gradient(-180deg, white, #fda3b2);
    display: flex;
    align-content: center;
    justify-content: center;
    transition: 0.5s;
  }
  

  .hexagon i {
    z-index: 1;
    margin: auto;
    font-size: 50px;
    color: transparent;
    background: linear-gradient(45deg, #a58fe9, #e37682);
    background-clip: text;
    -webkit-background-clip: text;
  }
  
  .hexagon:before,
  .hexagon:after {
    position: absolute;
    content: "";
    background: inherit;
    height: 100%;
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    transform-origin: center;
  }
  .hexagon:before {
    transform: rotateZ(60deg);
  }
  .hexagon:after {
    transform: rotateZ(-60deg);
  }
  .hexagon:hover {
    border-radius: 50px;
    transition: 0.5s;
  }
  .hexagon:hover:before {
    border-radius: 50px;
    transition: 0.5s;
  }
  .hexagon:hover:after {
    border-radius: 50px;
    transition: 0.5s;
  }
  
.values{
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    gap: 100px;
}
.maincontainer{

  display: flex;
  flex-direction: row;
  gap:100px;
  justify-content: center;
  
}
.profile{
  width: 50%;
  height:250px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5%;
  justify-content: center;
  color: white;
  font-size: large;
font-weight: 500;
}
.mainConatiner{
  margin-top: 10%;
  margin-bottom: 20%;
  display: flex;
  flex-direction: column;
  gap:100px;
  
}
.desc{
display: flex;
 justify-content: center;
 font-size: large;
 font-weight: 500;
 color: white;
}
.NavName{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: xx-large;
  font-weight: 600;
  color: white;
  gap:8px

}
.NavName div{
  width: 100px;
  height: 3px;
  background-color: white;
}
.empatyLine{
  height: 1px;
  width: 100%;
  background-color:white;
}
.navItem:hover .Navname{
  color: #a58fe9;
}
.Contact{
  height: 900px;
  background: hsl(353, 66%, 68%);
}
.DownArrow{
  cursor: pointer;
  display: flex;
  width: 100%;
  float: right;
  justify-content: end;
  margin-bottom: 20px;
}

.DownArrow i{
  font-size: 35px !important;
  float: right;
  color: white;
}


#triangle-down{
  position: relative;
  width: 0; 
  height: 0; 
  border-left: 600px solid transparent;
  border-right: 600px solid transparent;
  border-top: 70px solid #fcfcfc;
  
}

.detailsContact{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
 align-items: center;

}
input{
  width: 400px;
  height: 40px;
  color: white;
  border: transparent;
  background-color:  rgb(148, 81, 171);
  border-bottom: 0.5px solid white;
}
textarea{
  width: 400px;
  height: 100px;
  color: white;
  border: transparent;
  background-color: rgb(148, 81, 171);
  border-bottom: 0.5px solid white;
}
input::placeholder { 
  color: white;
}
textarea::placeholder{
  color: white;
}

.submit{
  margin-left: 330px;
  margin-top: 10px;
  outline: none;
  background-color:rgb(148, 81, 171);
  border-radius: 4px;
  border: 0.5px solid grey;
  padding: 8px;
  color: white;
  cursor: pointer;
}
.setPlace{
  margin-top: 36px
}
.submit:hover{
  background-color: plum;
  color: black;
}
.footer{
  margin-top: 20%;
  height: 208px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  display: flex;
  background-color:rgba(0, 0, 0, 0.2);
}
.arrowUp{
  display: flex;
  cursor: pointer;
  padding: 10px;
  height: 40px;
  align-items: center;
  margin-top: -20px;
  background-color: rgb(74, 39, 85);
}
.arrowUp i{
  display: flex;
  align-items: center;
  font-size: 30px; 
  color: white;
}
.website{
  display: flex;
    width: 100%;
    gap: 70px;
    justify-content: center;
}
.website a{
  height: 60px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(148, 81, 171);

}
.website a i{
  color:white;
  font-size: 30px;
}